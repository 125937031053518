import { ServiceRouter } from 'Router';
import ErrorPage from 'layouts/Layout/ErrorBoundary/error/error.route';

export const ErrorLabel = 'Errors';

export const errorRoutes: ServiceRouter = {
  error: {
    error: ErrorPage,
  }
};
