import { Resource, State } from 'ketting';
import { ClientAPI } from './clientAPI';
import { Filter } from './queries/filter';
import { Query } from './queries/query';
import { PagedCollectionResource } from './resources';

export interface UrlTemplates<TFilters extends Record<keyof TFilters, Filter<any>>> {
  query: string | ((query: Query<TFilters>) => string);
  exportURL?: string | ((query: Query<TFilters>) => string);
}

export abstract class BaseClient<TModel extends object, TFilters extends Record<keyof TFilters, Filter<any>>> {
  protected abstract defaultQuery: Readonly<Query<TFilters>>;
  protected abstract urlTemplates: UrlTemplates<TFilters>;

  fromResource = async (resource: Resource<TModel>): Promise<TModel> => resource.get().then(this.fromState);
  abstract fromState(state: State<TModel>): Promise<TModel>;

  query = (queryBuilder: (defaultQuery: Query<TFilters>) => Query<TFilters> = t => t) =>
    this.queryApi<TModel, TFilters>(this.urlTemplates.query, this.defaultQuery, queryBuilder, this.fromResource);
  
  getCsvURL = (queryBuilder: (defaultQuery: Query<TFilters>) => Query<TFilters> = t => t) =>
    this.queryApi<TModel, TFilters>(this.urlTemplates.exportURL || '', this.defaultQuery, queryBuilder).url;

  protected queryApi = <TModel extends object, TModelFilters extends Record<keyof TModelFilters, Filter<any>>>(
    urlTemplate: string | ((query: Query<TModelFilters>) => string),
    defaultQuery: Query<TModelFilters>,
    queryBuilder: (defaultQuery: Query<TModelFilters>) => Query<TModelFilters>,
    fromResource?: (resource: Resource<TModel>) => Promise<TModel>
  ) => {
    const query = queryBuilder(defaultQuery);

    if (typeof urlTemplate === 'function') urlTemplate = urlTemplate(query);

    const url = ClientAPI.resolveUriTemplate(urlTemplate, {
      searchTerm: query.searchTerm,
      ...query.serializeFilters(),
      ...query.pagination,
    });

    fromResource ??= async itemResource => (await itemResource.get()).data;
    return {
      get: () => this.queryInternal(url, fromResource!),
      url,
    };
  };

  protected queryInternal = async <TModel extends Object>(url: string, fromResource: (resource: Resource<TModel>) => Promise<TModel>) => {
    try {
      const resource = ClientAPI.getInstance().go<PagedCollectionResource>(url);
      const itemResources = await (await resource.refresh()).followAll<TModel>('item');
      const data = await Promise.all(itemResources.map(fromResource));
      const state = await resource.get();
      return { data, resource, state };
    } catch (error) {
      console.log(error)
      return ClientAPI.redirectOnError(error, true);
    }
  };

  queryUrl = async (url: string) => this.queryInternal(url, this.fromResource);
}
