import { PermissionToken } from 'api/authorization/userPermission';
import { UserFeature } from 'api/authorization/userPermission/userFeatures';
import userFeaturesClient from 'api/authorization/userPermission/userFeaturesClient';
import authorizationClient from 'api/authorization/userPermission/userPermissionClient';
import { useUser } from 'common/hooks/useUser';
import { useEffect, useState } from 'react';
import { AuthorizationContext } from '.';

interface AuthorizationProps {
  children: React.ReactNode;
}

export const AuthorizationProvider = ({ children }: AuthorizationProps) => {
  const user = useUser();
  const [permissions, setPermissions] = useState<PermissionToken | null>(null! as PermissionToken);
  const [userFeatures, setUserFeatures] = useState<UserFeature | null>(null! as UserFeature);

  const getPermission = async () => {
    const permission = await authorizationClient.getPermissions();
    setPermissions(permission);
  };

  const getUserFeatures = async () => {
    const features = await userFeaturesClient.getFeatures();
    setUserFeatures(features);
  }

  useEffect(() => {
    if (user) {
      getPermission();
      getUserFeatures();
    }
  }, [user]);

  return <AuthorizationContext.Provider value={{ feature: userFeatures, permissonToken: permissions }}>{children}</AuthorizationContext.Provider>;
};
