import classNames from 'classnames';

export const Styles = {
  conatiner: (isCollapsed: boolean) =>
    classNames([
      'top-0 left-0 bg-grayscale-100 h-screen ease-in-out duration-300 flex flex-col xs:w-full shadow-level1 sticky z-10',
      isCollapsed ? 'l:w-13' : 'l:w-[18rem]',
    ]),

  content: (isCollapsed: boolean) => classNames(['flex flex-col flex-1 mb-13', !isCollapsed && 'overflow-x-hidden overflow-y-auto']),
  icon: (isCollapsed: boolean, isMenuItemSelected: boolean) =>
    classNames(['h-[18px] w-6 menu-item-icon', isMenuItemSelected && 'active', isCollapsed && 'translate-x-1/2']),
};
