import { ReactComponent as Bullet } from 'assets/icons/bullet.svg';
import { Styles } from './submenuitem.styles';
import { Button } from 'common/components/Button';

export interface SubMenuItemProps {
  label: string;
  onClick: () => void;
  isSubMenuItemSelected?: boolean;
  isDisabled?: boolean;
  hasSubMenuAccess: boolean;
}

export const SubMenuItem = ({ label, isSubMenuItemSelected, isDisabled, onClick }: SubMenuItemProps) => {
  return (
    <Button
      id={label}
      variant='None'
      className={Styles.listStyles(!!isDisabled, !!isSubMenuItemSelected)}
      classNameSpan={Styles.labelStyles(!!isDisabled, !!isSubMenuItemSelected)}
      icon={<Bullet className={Styles.bulletStyles(!!isDisabled, !!isSubMenuItemSelected)} />}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};
