import config from 'config/config';
import Scope from 'api/authorization/Scope';
import Permission from '../Permissions';

export { Scope };

export interface IPermissionToken {
  scopes: PermissionScope[];
}

export interface UserFeatures {
  features: string[]
}

export interface PermissionScope {
  scope: Scope;
  permissions: Permission[];
  subscopes: PermissionScope[];
}

export interface ResourceAction {
  service: string;
  resource: string;
  action: string;
}

export interface AuthorizedMenuSubMenu {
  menu: string[];
  submenu: string[];
}

function createScopes(permissionScopes: PermissionScope[]): PermissionScope[] {
  return permissionScopes.map(s => ({
    ...s,
    scope: new Scope(s.scope.type, s.scope.brandId, s.scope.groupId, s.scope.storeId),
    subscopes: s.subscopes && createScopes(s.subscopes),
    permissions: s.permissions && s.permissions.map(p => new Permission(p.service, p.resource, p.action)),
  }));
}

export class PermissionToken {
  scopes: PermissionScope[];

  constructor(apiResult: IPermissionToken) {
    this.scopes = createScopes(apiResult.scopes);
  }

  hasPermissions = (scope: Scope, resourceAction: ResourceAction): boolean => {
    if (scope === Scope.unknown) {
      return this.traverseScope(this.scopes, resourceAction, this.checkReadPermission, scope);
    }
    return this.traverseScope(this.scopes, resourceAction, this.checkPermissions, scope);
  };

  hasReadPermissions = (resourceAction: ResourceAction): boolean =>
    this.traverseScope(this.scopes, resourceAction, this.checkReadPermission);

  private checkReadPermission = (permissionScope: PermissionScope, resourceAction: ResourceAction) =>
    permissionScope.permissions.some((x: Permission) => x.matches(resourceAction));

  private checkPermissions = (permissionScope: PermissionScope, resourceAction: ResourceAction, scope?: Scope) =>
    permissionScope.scope.contains(scope!) && permissionScope.permissions.some(p => p.matches(resourceAction));

  permittedStores = (action: ResourceAction): string[] | string => {
    let storeIds: string[] = [];
    return this.getStoreIds(this.scopes, action, storeIds);
  };

  getStoreIds = (scope: PermissionScope[], action: ResourceAction, storeIds: string[]): string[] | string => {
    scope.flatMap(x => {
      if (x.permissions.some(s => s.matches(action)) && x.scope.storeId) {
        storeIds.push(x.scope.storeId);
      } else if (x.subscopes) {
        this.getStoreIds(x.subscopes, action, storeIds);
      }
    });

    return storeIds.includes(Scope.all) ? Scope.all : storeIds;
  };

  private traverseScope = (
    scopes: PermissionScope[],
    resourceAction: ResourceAction,
    checkPermission: (permissionScope: PermissionScope, action: ResourceAction, scope?: Scope) => boolean,
    componentScope?: Scope
  ): boolean =>
    scopes.some(
      s =>
        checkPermission(s, resourceAction, componentScope) ||
        this.traverseScope(s.subscopes, resourceAction, checkPermission, componentScope)
    )
      ? true
      : config.disableAuthorization; // should return false when authorization is enabled
}
