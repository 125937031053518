import { AuthorizationContextProps } from 'common/authorization/AuthorizationContext';
import { Route, ServiceRouter } from './index';

export const mapListPages = (
  serviceRouter: ServiceRouter,
  handleClick: (screen: Route) => void,
  selectedSubMenuItem: string,
  permission: AuthorizationContextProps | null
) => {
  return Object.keys(serviceRouter)
    .filter(el => 'list' in serviceRouter[el])
    .map(el => ({
      label: serviceRouter[el].list.label,
      isSubMenuItemSelected: selectedSubMenuItem.includes(serviceRouter[el].list.label),
      onClick: () => handleClick(serviceRouter[el].list),
      hasSubMenuAccess: permission?.feature?.hasMenuAccess(serviceRouter[el].list.feature!) ?? false,
    }));
};
