import { BaseClient } from 'api/BaseClient';
import { ClientAPI } from 'api/clientAPI';
import config from 'config/config';
import { State } from 'ketting';
import { UserFeatures } from '.';
import { UserFeature } from './userFeatures';
import { AuthorizationFilters, defaultQuery } from './userPermissionQuery';

export class UserFeaturesClient extends BaseClient<UserFeatures, AuthorizationFilters> {
  protected defaultQuery = defaultQuery;
  protected urlTemplates = {
    query: '',
    get: '/api/authorization/user/profile'
  };

  getFeatures = async () => {
    const response = (await ClientAPI.get<UserFeatures>(this.urlTemplates.get));
    let features = config.disableAuthorization ? ['Super User'] : (await this.fromState(response.state as State<UserFeatures>)).features;
    return new UserFeature(features);
  }

  fromState = (state: State<UserFeatures>): Promise<UserFeatures> => Promise.resolve(state.data);
}

const userFeaturesClient = new UserFeaturesClient();

export default userFeaturesClient;
