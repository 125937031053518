import { ServiceRouter } from 'Router';
import cashDepositCreateRoute from './CashDeposits/Create/cashDepositCreate.route';
import cashDepositEditRoute from './CashDeposits/Edit/cashDepositEdit.route';
import cashDepositRoute from './CashDeposits/List/cashDeposit.route';

export const CashAndSalesLabel = "Cash and Sales"

export const cashAndSalesRoutes: ServiceRouter = {
  Deposit: {
    list: cashDepositRoute,
    create: cashDepositCreateRoute,
    edit: cashDepositEditRoute
  }
};
