export enum SpinnerAlignmentEnum {
  Up = 'flex-col',
  Down = 'flex-col-reverse',
  Left = 'flex-row ',
  Right = 'flex-row-reverse ',
}

export enum SpinnerSizeEnum {
  S = 'w-6 h-[19px] m-[18px]',
  M = 'w-[27px] h-[27px] m-[18.5px]',
  L = 'w-[34px] h-[34px] m-[19px]',
  XS = 'w-4 h-[12px] m-[4px]'
}
export enum SpinnerFontSizeEnum {
  XS = 'text-[8px]', 
  S = 'text-[12px]',
  M = 'text-[14px]',
  L = 'text-[16px]',
}
export interface SpinnerProps {
  size?: 'M' | 'L' | 'S' | 'XS';
  alignment?: 'Up' | 'Down' | 'Left' | 'Right';
  label?: string;
  noMargin?: true;
  className?: string;
}

export const Spinner = ({ size, alignment, label, noMargin, className }: SpinnerProps) => {
  return (
    <div className={`flex ${SpinnerAlignmentEnum[alignment ?? 'Up']} items-center justify-center ${className}`}>
      <span className={`font-normal not-italic ${SpinnerFontSizeEnum[size ?? 'S']}`}>{label}</span>
      <div
        className={`inline-block ${
          SpinnerSizeEnum[size ?? 'S']
        } ${size === 'XS' ? 'border-[4px]' : 'border-[6px]'}   border-t-primary-100  border-primary-400 rounded-full spinner-border animate-spin ${noMargin ? 'm-0' : ''}`}
        role="status"
      />
    </div>
  );
};
