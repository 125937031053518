import { Link } from 'react-router-dom';

interface BreadcrumbItem {
  url: string;
  name: string;
}

interface BreadcrumbProps {
  breadcrumbItems: BreadcrumbItem[];
}

export const ShowCollapseBreadcrumbItems = ({ breadcrumbItems }: BreadcrumbProps) => {
  const renderItems = (item: BreadcrumbItem, index: number, aSize: number) => {
    if (index === 0 || index === aSize - 1) return <></>;
    return (
      <li key={index} className="p-3">
        <Link className="hover:underline" to={item.url}>
          {item.name}
        </Link>
      </li>
    );
  };
  return (
    <div className="relative inline-block inset-0 rounded-l bg-grayscale-100">
      <ol className="absolute rounded-l w-18 min-h-min p-2 mt-3 -ml-3 bg-grayscale-100">
        {breadcrumbItems.map((item, index, array) => renderItems(item, index, array.length))}
      </ol>
    </div>
  );
};
