import { useHistory } from 'react-router';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import config from 'config/config';

const oktaAuth = new OktaAuth(config.okta.auth);

const OktaAuthentication = ({ children }: React.PropsWithChildren<{}>) => {
  const history = useHistory();

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={() => history.push('/login')}
      restoreOriginalUri={(_, returnUrl) => history.replace(toRelativeUrl(returnUrl ?? window.location.origin, window.location.origin))}
    >
      {children}
    </Security>
  );
};

export default OktaAuthentication;

export { oktaAuth };

export { LoginCallback, SecureRoute } from '@okta/okta-react';
