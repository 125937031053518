import { ServiceRouter } from 'Router';

import storedetails from 'organization/Stores/Details/storedetails.route';
import storecreate from 'organization/Stores/Create/newstore.route';
import storelist from 'organization/Stores/List/storelist.route';
import manageFBCRoute from 'organization/Stores/Edit/ManageFBC/manageFBC.route';

import grouplist from 'organization/Groups/List/GroupTable/grouplist.route';
import groupdetails from 'organization/Groups/Details/groupdetails.route';
import creategroup from 'organization/Groups/Create/creategroup.route';
import replaceFranchiseMainRoute from 'organization/Groups/Details/LeftPanel/ContactsSection/MenuActions/ReplaceFranchiseMain/replaceFranchiseMain.route';

import createrealignmentplan from 'organization/Realignments/Create/createrealignmentplan.route';
import editRealignmentPlan from 'organization/Realignments/Edit/editRealignmentPlan.route';
import createContact from 'organization/Groups/Contact/Create/createcontact.route';
import editContact from 'organization/Groups/Contact/Edit/editcontact.route';

import realignmentList from 'organization/Realignments/List/realignmentList.route';
import PeopleList from 'organization/People/List/peopleList.route';
import PeopleDetails from 'organization/People/Details/peopleDetails.route';

import transferList from 'organization/Transfers/List/transferList.route';
import initiateTransfer from './Transfers/Initiate/initiateTransfer.route';
import editTransferRoute from './Transfers/Edit/editTransferRoute';
import createPerson from './People/Create/createPerson.route';
import editPerson from './People/Edit/editPerson.route';
import manageAssignment from './People/Assignments/Manage/manageAssignment.route';

export const OrganizationLabel = 'Organization';

export const organizationRoutes: ServiceRouter = {
  'Franchise Group': {
    list: grouplist,
    details: groupdetails,
    create: creategroup,
    createContact: createContact,
    editContact: editContact,
    replaceFranchiseMainRoute: replaceFranchiseMainRoute,
  },
  Store: {
    list: storelist,
    details: storedetails,
    create: storecreate,
    manageFBC: manageFBCRoute,
  },
  Person: {
    create: createPerson,
    edit: editPerson,
    list: PeopleList,
    details: PeopleDetails,
    manageAssignment: manageAssignment,
  },
  "Execution Plan": {
    create: createrealignmentplan,
    list: realignmentList,
    edit: editRealignmentPlan,
  },
  'Store Transfer': {
    list: transferList,
    create: initiateTransfer,
    edit: editTransferRoute,
  },
};
