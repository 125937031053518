import { ReactComponent as UpArrow } from 'assets/icons/upArrow.svg';
import { Button } from 'common/components/Button';
import { SubMenu } from './SubMenu';
import { SubMenuItemProps } from './SubMenu/SubMenuItem/index';
export interface MenuItemProps {
  label: string;
  icon?: React.ReactNode;
  isCollapsed: boolean;
  isSelected: boolean;
  onClick: () => void;
  subMenuItems?: SubMenuItemProps[];
  hideSubMenu: boolean;
}

export const MenuItem = ({ isCollapsed, label, icon, isSelected, onClick, subMenuItems, hideSubMenu }: MenuItemProps) => {
  const renderDropdownArrow = () => {
    if (isCollapsed || subMenuItems === undefined) return <></>;

    return <UpArrow className={`${isSelected ? 'stroke-grayscale-white' : 'stroke-primary-700 rotate-180'} justify-end mr-[29px]`} />;
  };

  return (
    <div className="relative">
      <Button
        onClick={onClick}
        variant="None"
        id={label}
        className={'w-full'}
        classNameSpan={`flex items-center gap-[10px] w-full px-0 ${
          isSelected && 'bg-primary-500'
        } h-12 rounded-tl-s rounded-bl-s overflow-hidden`}
      >
        <span className={`h-full ${isSelected && 'bg-primary-400'} w-3 overflow-hidden`}></span>
        {icon}
        <p
          className={`${isCollapsed && 'w-[0px] h-[0px]'} m-0 p-0 ease-in-out duration-300 overflow-hidden text-left flex flex-1 ${
            isSelected ? 'text-grayscale-100' : 'text-primary-700'
          } font-semibold`}
        >
          {label}
        </p>

        {renderDropdownArrow()}
      </Button>

      {subMenuItems && (
        <SubMenu
          isCollapsed={isCollapsed}
          isSelected={isSelected}
          menuTitle={label}
          subMenuItems={subMenuItems}
          hideSubMenu={hideSubMenu}
        />
      )}
    </div>
  );
};
