import { BaseClient } from 'api/BaseClient';
import { ClientAPI } from 'api/clientAPI';
import config from 'config/config';
import { State } from 'ketting';
import { IPermissionToken, PermissionToken } from '.';
import { AuthorizationFilters, defaultQuery } from './userPermissionQuery';

export class AuthorizationClient extends BaseClient<IPermissionToken, AuthorizationFilters> {
  protected defaultQuery = defaultQuery;
  protected urlTemplates = {
    query: '',
    get: '/api/authorization/user/permission-token',
  };

  getPermissions = async () => {
    const data = config.disableAuthorization ? { scopes: [] } : (await ClientAPI.getById<IPermissionToken>(this.urlTemplates.get)).data;
    return new PermissionToken(data);
  };

  fromState = (state: State<PermissionToken>): Promise<PermissionToken> => Promise.resolve(state.data);
}

const authorizationClient = new AuthorizationClient();

export default authorizationClient;
