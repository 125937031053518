enum ButtonVariantEnum {
  Primary = 'h-9 min-w-[4rem] text-grayscale-white bg-primary-400 rounded-s hover:bg-primary-500  focus:bg-primary-400 focus:border-solid focus:border-primary-200 disabled:bg-grayscale-300 disabled:text-grayscale-400 disabled:border-grayscale-300 disabled:cursor-not-allowed',
  Secondary = 'h-9 min-w-[4rem] text-primary-400 bg-transparent font-semibold rounded-s hover:bg-primary-50 border border-blue disabled:text-grayscale-300 disabled:border-grayscale-300 disabled:cursor-not-allowed',
  None = '',
}

enum IconPositionEnum {
  Right = 'Right',
  Left = 'Left',
}

enum IconButtonSizeEnum {
  xxs = 'w-7 h-7',
  xs = 'w-8 h-8',
  s = 'w-9 h-9',
  m = 'w-10 h-10',
  l = 'w-11 h-11',
}

enum IconButtonInnerSizeEnum {
  xxs = 'w-4 h-4',
  xs = 'w-4 h-4',
  s = 'w-[14px] h-[14px]',
  m = 'w-[18px] h-[18px]',
  l = 'w-7 h-7',
}

export {
  ButtonVariantEnum,
  IconPositionEnum,
  IconButtonSizeEnum,
  IconButtonInnerSizeEnum
}
