import { ServiceRouter } from 'Router';

import createIngredient from 'menu/Ingredients/CreateIngredient/createingredient.route';
import editIngredient from 'menu/Ingredients/EditIngredient/editingredient.route';
import ingredientList from 'menu/Ingredients/IngredientsListing/ingredientlist.route';

import createPromotion from 'menu/Promotions/CreatePromotion/createpromotion.route';
import editPromotion from 'menu/Promotions/EditPromotion/editpromotion.route';
import promotionList from 'menu/Promotions/PromotionsList/promotionlist.route';

import createRecipe from 'menu/Recipes/Base/CreateRecipe/createbase.route';
import editRecipe from 'menu/Recipes/Base/EditRecipe/editrecipe.route';
import recipeList from 'menu/Recipes/RecipeList/recipelist.route';
import recipeDetails from 'menu/Recipes/RecipeDetails/recipeDetails.route'

import batchList from 'menu/Recipes/Batch/BatchesListing/batchlist.route';
import editBatch from 'menu/Recipes/Batch/EditBatch/editbatch.route';
import createBatch from 'menu/Recipes/Batch/CreateBatch/batchcreate.route';

import editVarient from 'menu/Recipes/Variant/editvarient.route';

import createIngredientSet from 'menu/Recipes/IngredientSets/CreateIngredientSet/createIngredientset.route';
import editIngredientSet from 'menu/Recipes/IngredientSets/EditIngredientSet/editIngredientset.route';
import ingredientsetList from 'menu/Recipes/IngredientSets/IngredientSetListing/ingredientsetList.route';

import modiefierList from 'menu/Recipes/Modifiers/ModifiersListing/modifierlist.route';
import createModifier from 'menu/Recipes/Modifiers/CreateModifier/createmodifier.route';
import editModifier from 'menu/Recipes/Modifiers/EditModifier/editmodifier.route';

import storemenuCreate from 'menu/StoreMenu/Menu/CreateStoreMenu/createStoremenu.route';
import storeMenuEdit from 'menu/StoreMenu/Menu/EditStoreMenu/editStoremenu.route'
import storemenuList from 'menu/StoreMenu/StoreMenusList/storemenulist.route';
import assignStoreRoute from './StoreMenu/AssignStoresMenu/assignStore.route';

import createBlock from 'menu/StoreMenu/Block/CreateBlock/createblock.route';
import editBaseBlock from 'menu/StoreMenu/Block/Base/EditBaseBlock/editBaseBlock.route';
import editVariantBlock from 'menu/StoreMenu/Block/Variant/EditVariantBlock/editVariantBlock.route';
export const RestaurantLabel = 'Restaurant';

export const restaurantRoutes: ServiceRouter = {
  "Raw Ingredient": {
    create: createIngredient,
    edit: editIngredient,
    list: ingredientList,
  },
  "Ingredient Set": {
    create: createIngredientSet,
    edit: editIngredientSet,
    list: ingredientsetList,
  },
  Recipe: {
    create: createRecipe,
    edit: editRecipe,
    list: recipeList,
    details:recipeDetails,
  },
  "Batch Ingredient": {
    create: createBatch,
    edit: editBatch,
    list: batchList,
  },
  Modifier: {
    create: createModifier,
    edit: editModifier,
    list: modiefierList,
  },
  Promotion: {
    create: createPromotion,
    edit: editPromotion,
    list: promotionList,
  },
  RecipeVariant: {
    edit: editVarient,
  },
  Menu: {
    create: storemenuCreate,
    edit: storeMenuEdit,
    list: storemenuList,
  },
  BaseMenuBlock: {
    create: createBlock,
    edit: editBaseBlock,
  },
  MenuBlockVariant: {
    edit: editVariantBlock,
  },
  AssignStore: {
    create: assignStoreRoute,
  }
};
