import { ServiceRouter } from 'Router';
import createProduct from 'purchasing/Products/NewProduct/createProduct.route';
import productList from 'purchasing/Products/ProductsListing/ProductTable/productList.route';
import productDetails from 'purchasing/Products/ProductDetails/productDetails.route'; 

import createDistributor from 'purchasing/Distributors/Create/createDistributor.route';
import createDistributionCenter from 'purchasing/Distributors/Details/DistributionCenter/Create/createDistributionCenter.route';

import distributorList from 'purchasing/Distributors/List/distributorList.route';
import distributorDetails from 'purchasing/Distributors/Details/distrbutorDetails.route';

import createVendor from 'purchasing/Vendor/Create/createVendor.route';
import vendorList from 'purchasing/Vendor/List/VendorTable/vendorList.route';

import invoiceList from 'purchasing/Invoices/List/invoiceList.route';
import invoiceDetails from 'purchasing/Invoices/InvoiceDetails/invoiceDetails.route';
import receivingList from 'purchasing/Receiving/List/receivingList.route';
import receivingInventory from 'purchasing/Receiving/ReceiveInventory/receiveInventory.route';


export const PurchasingLabel = 'Purchasing';

export const purchasingRoutes: ServiceRouter = {
  Product: {
    create: createProduct,
    list: productList,
    details: productDetails
  },
  Distributor: {
    create: createDistributor,
    list: distributorList,
    details: distributorDetails,
  },
  DistributionCenter: {
    create: createDistributionCenter,
  },
  Vendor: {
    create: createVendor,
    list: vendorList,
  },
  Invoice: {
    list: invoiceList,
    details: invoiceDetails,
  },
  Receiving: {
    list: receivingList,
    details: receivingInventory,
  },
};
