import { ReactComponent as LeftArrow } from 'assets/icons/leftArrow.svg';
import { ReactComponent as RightArrow } from 'assets/icons/rightArrow.svg';
import { Button } from 'common/components/Button';

export interface CollapseProps {
  isCollapsed: boolean;
  onClick: () => void;
}

export const Collapse = ({ isCollapsed, onClick }: CollapseProps) => {
  return (
    <div onClick={onClick} className={`hover:bg-grayscale-300 bg-grayscale-200 cursor-pointer overflow-hidden group absolute bottom-0 left-0 right-0`}>
      <div
        className={`
        ${isCollapsed ? 'translate-x-[10%]' : 'translate-x-[80%]'}
        ${!isCollapsed && 'group-hover:translate-x-[78%]'}
        ${isCollapsed && 'group-hover:translate-x-[20%]'}
        ease-in-out duration-300 flex py-7 px-5 xs:hidden l:flex
        `}
      >
        <Button
          onClick={()=>{}}
          variant='None'
          id='arrow-collapse'
          icon={isCollapsed ? <RightArrow /> : <LeftArrow />}
        />
      </div>
    </div>
  );
};
