import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import dayjs from 'dayjs';

// Dayjs plugins
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

//Dayjs locale configuration
dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    m: '%d minute',
    mm: '%d minutes',
    h: '1 hour',
    hh: '%d hours',
    d: '1 day',
    dd: (value: number) => {
      const weeks = Math.floor(value / 7);

      if (weeks === 1) return `1 week`;
      if (weeks >= 2) return `${weeks} weeks`;
      return `${value} days`;
    },
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years',
  },
});
