import { useState } from 'react';
import { Collapse } from './Collapse';
import { Header } from './Header';
import { MenuItem } from './MenuItem';
import { SubMenuItemProps } from './MenuItem/SubMenu/SubMenuItem/index';
import { Styles } from './sidebarnavigation.styles';

export interface IMenuItem {
  label: string;
  icon?: React.ReactNode;
  isMenuItemSelected: boolean;
  subMenuItems?: SubMenuItemProps[];
  onClick?: () => void;
  hasMenuAccess: boolean;
}
export interface SideBarNavigationProps {
  menuItems: IMenuItem[];
  hideSubMenu: boolean;
}

export const SideBarNavigation = ({ menuItems, hideSubMenu }: SideBarNavigationProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const handleCollapsedOnClick = () => setIsCollapsed(!isCollapsed);

  return (
    <div className={Styles.conatiner(isCollapsed)}>
      <Header isCollapsed={isCollapsed} />
      <div className={Styles.content(isCollapsed)}>
        {menuItems.map((item: IMenuItem) => {
          const { label, icon, onClick, isMenuItemSelected } = item;

          return (
            <MenuItem
              isCollapsed={isCollapsed}
              label={label}
              icon={<span className={Styles.icon(isCollapsed, isMenuItemSelected)}>{icon}</span>}
              isSelected={isMenuItemSelected}
              onClick={() => onClick && onClick()}
              subMenuItems={item?.subMenuItems?.filter(subMenu => subMenu.hasSubMenuAccess)}
              key={label}
              hideSubMenu={hideSubMenu}
            />
          );
        })}
      </div>

      <Collapse isCollapsed={isCollapsed} onClick={handleCollapsedOnClick} />
    </div>
  );
};