import classNames from "classnames";

export interface IAvatarProps {
  image?: React.ReactNode;
  name?: string;
  isCollapsed?: boolean;
}

export const Avatar = ({ image, name, isCollapsed }: IAvatarProps) => {
  if (image) {
    return <>{image}</>;
  }

  const getFormattedDisplayName = () => {
    const splitName = name?.split(' ');
    if (splitName && splitName.length > 1) {
      const firstLetterFirstName = splitName[0].charAt(0).toUpperCase();
      const firstLetterLastName = splitName[splitName.length - 1].charAt(0).toUpperCase();
      return `${firstLetterFirstName}${firstLetterLastName}`;
    }

    return '';
  };

  return (
    <div className={classNames('flex items-center justify-center font-semibold rounded-full select-none bg-primary-100', { 'w-full h-2/3': isCollapsed,  'w-9 h-9': !isCollapsed})}>
      <div>{getFormattedDisplayName()}</div>
    </div>
  );
};
