import { Button } from 'common/components/Button';
import { ReactComponent as Home } from 'assets/icons/home.svg';
import { ReactComponent as ReturnIcon } from 'assets/icons/ic_return.svg';
import { ReactComponent as EmptyTaco } from 'assets/icons/empty_taco.svg';
import { ReactComponent as Taco500 } from 'assets/icons/taco_error.svg';
import { useHistory, useParams } from 'react-router';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';


const ErrorPage = () => {
  const param = useParams<{ error: string }>();
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    setTimeout(() => {
    if(param?.error?.includes('401'))  oktaAuth.signOut();
    }, 1000)
  }, [])

  console.log(param.error);
  const renderButtons = () => {
    return (
      <div className="flex flex-row gap-7">
        <Button
          id="homeButton"
          className="h-full"
          type="button"
          onClick={() => {
            history.push(`/`);
            window.location.reload();
          }}
        >
          <Home className="fill-grayscale-white" /> <p className="p-2 text-grayscale-white">Go Home</p>
        </Button>
        <Button
          id="backButton"
          variant="Secondary"
          className="float-right h-full"
          type="button"
          onClick={() => {
            history.go(-1);
            window.location.reload();
          }}
        >
          <ReturnIcon /> <p className="p-2 text-primary-400">Go Back</p>
        </Button>
      </div>
    );
  };

  const errorContent = (error: string, heading: string, line1: string, line2: string) => {
    return (
      <div className="flex flex-row ml-12 gap-14 p-14">
        <div className="flex flex-col gap-4">
          <span className="pt-5 pb-5 font-bold text-8xl text-primary-400">{error}</span>
          <span className="pt-5 pb-5 text-3xl font-bold text-grayscale-500"> {heading} </span>
          <span className="pt-0 pb-0 text-l text-grayscale-black"> {line1} </span>
          <span className="pt-0 pb-10 text-l text-grayscale-black"> {line2} </span>
          {renderButtons()}
        </div>
        {param?.error?.includes('500') ? <Taco500 className="p-8" /> : <EmptyTaco />}
      </div>
    );
  };

  return (
    <>
      {param?.error?.includes('404') && errorContent('404', 'Page Not Found', "The page you're trying to reach does not exist.", 'Please check the address.')}
      {param?.error?.includes('500') && errorContent('500', 'Internal Server Error', "Something went wrong... We're working on it.", 'Please try it later.')}
      {param?.error?.includes('403') && errorContent('403', 'FORBIDDEN!', "The page you're trying to reach is unavailable.", 'Please obtain the required Authorization Access.')}
      {param?.error?.includes('401') && errorContent('401 - Unauthorized Access!', 'Redirecting to Login page...', "", "")}
      {!param?.error && errorContent('', 'OOPS!', "Something went wrong... We're working on it.", 'Please try it later.')}
    </>
  );
};

export default ErrorPage;
