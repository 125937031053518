import React from 'react';
import { Button } from '../Button';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

interface ModalProps {
  children: React.ReactNode;
  handleClose: () => void;
  show: boolean;
  title?: React.ReactNode;
  button1Label: string;
  onClickButton1: () => void;
  button2Label?: string;
  onClickButton2?: () => void;
  button1Type?:  'submit' | 'reset' | 'button';
  button2Type?:  'submit' | 'reset' | 'button';
  className?: string;
  footerMessage?: string;
}
export const Modal = ({ children, show, title, handleClose, button1Label, onClickButton1, button2Label, onClickButton2, button1Type='button', button2Type='button', className, footerMessage}: ModalProps) => {
  const renderButtons = () => {
    if (!button2Label)
      return (
        <Button
          id={button1Label+'-modalButton'}
          variant='Secondary'
          className='float-right'
          type={button1Type}
          onClick={onClickButton1}
        >
          {button1Label}
        </Button>
      );
    return (
      <div className='flex flex-row gap-7'>
        <Button
          id={button1Label+'-modalButton'}
          variant='Secondary'
          className='ml-18'
          type={button1Type}
          onClick={onClickButton1}
        >
          {button1Label}
        </Button>
        <Button
          id={button2Label+'-modalButton'}
          className='float-right'
          type={button2Type}
          onClick={onClickButton2!}
        >
          {button2Label}
        </Button>

      </div>
    );
  };
  return (
    <div
      className={`fixed inset-0 z-[70] bg-opacity-70 bg-grayscale-black flex items-center justify-center ${
        show ? '' : 'hidden'
      } transition duration-300 ease-in-out`}
      onClick={handleClose}
    >
      <div className={`w-1/3 bg-grayscale-white rounded-m ${className}`} onClick={e => e.stopPropagation()}>
        <div className="flex flex-row p-4">
          {title}
          <button type="button" className="p-5 ml-auto -my-5 ">
            <CloseIcon onClick={handleClose} />
          </button>
        </div>
        <div className="p-4">{children}</div>
        <h4 className="pl-6">{footerMessage}</h4>
        <div className="flex flex-row justify-end gap-8 p-4 rounded-m bg-grayscale-200">{renderButtons()}</div>
      </div>
    </div>
  );
};
