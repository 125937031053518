const ariaType = (ariaFunction: string, isAriaPressedOrExpanded?:boolean, idAriaElementExpanded?:string): React.AriaAttributes | undefined => {

  let response : React.AriaAttributes | undefined;

  switch( ariaFunction ) {
    case 'Normal':
      response = undefined;
      break;
    case 'Toggle':
      response = {"aria-pressed":isAriaPressedOrExpanded};
      break;
    case 'Expanded':
      response = {
        "aria-expanded":isAriaPressedOrExpanded,
        "aria-controls":idAriaElementExpanded
      };
      break;
    default:
      response = undefined;
  }
  return response;

}

export {
  ariaType
}
