import classNames from 'classnames';

export const Styles = {
  listStyles: (isDisabled: boolean, isSubMenuItemSelected: boolean) =>
    classNames([
      'w-[16.5rem] h-[2.875rem] justify-start items-center flex gap-5 pl-7 xs:w-full group',
      isDisabled ? 'cursor-not-allowed' : 'hover:bg-primary-100 cursor-pointer',
      isSubMenuItemSelected ? 'bg-primary-100' : 'bg-primary-50',
    ]),

  bulletStyles: (isDisabled: boolean, isSubMenuItemSelected: boolean) =>
    classNames([
      isDisabled ? 'fill-grayscale-400' : 'fill-primary-700',
      isSubMenuItemSelected ? 'fill-primary-400': 'fill-primary-700',
    ]),

  labelStyles: (isDisabled: boolean, isSubMenuItemSelected: boolean) =>
    classNames([
      isDisabled ? 'text-grayscale-400' : 'text-grayscale-black group-hover:text-primary-400',
      isSubMenuItemSelected && 'text-primary-400',
      'font-medium gap-5 px-0',
    ]),
};
