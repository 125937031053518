import { ServiceRouter } from 'Router';

import createItem from './CreateInventoryItem/createInventory.route';
import itemList from './InventoryListing/InventoryTable/inventoryList.route';
import editItem from './EditInventoryItem/editInventory.route';

export const InventoryLabel = 'Inventory';

export const inventoryRoutes: ServiceRouter = {
  item: {
    create: createItem,
    edit: editItem,
    list: itemList,
  },
};
