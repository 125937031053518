import { Link } from 'react-router-dom';

interface ToastLink {
  message: string;
  linktext: string;
}

export const ToastwithLink = ({ message, linktext }: ToastLink) => {
  return (
    <>
      {message}
      <span className="text-primary-300  text-right pl-5 underline">
        <Link to={linktext}>Action</Link>
      </span>
    </>
  );
};
