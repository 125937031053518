import { useState } from 'react';
import { Link } from 'react-router-dom';
import { BreadcrumbItem } from 'common/components/Breadcrumb/BreadcrumbItem';
import { ShowCollapseBreadcrumbItems } from 'common/components/Breadcrumb/ShowCollapseBreadcrumbItems';
import { ReactComponent as BrandIcon } from 'assets/icons/brand.svg';
import { ReactComponent as GreaterThan } from 'assets/icons/greater_than.svg';
import useMediaQuery from 'common/hooks/useMediaQuery';
import { ReactComponent as ThreeDotIcon } from 'assets/icons/threeDot.svg';
import { ScreenSizeEnum } from 'utils/screenSizeEnum';

enum BreadcrumbLimitEnum {
  FirstItem = 0,
  CollapseLimit = 3,
}
export interface BreadcrumbItemProps {
  url: string;
  name: string;
}

interface BreadcrumbProps {
  breadcrumbItems: BreadcrumbItemProps[];
}
export const Breadcrumb = ({ breadcrumbItems }: BreadcrumbProps) => {
  const isMobile = useMediaQuery(ScreenSizeEnum.mobile);
  const [showItems, setShowItem] = useState(false);
  const handleCollapse = () => {
    setShowItem(!showItems);
  };
  const renderBreadcrumbs = () => {
    if (isMobile && breadcrumbItems.length > BreadcrumbLimitEnum.CollapseLimit) {
      return (
        <>
          <BreadcrumbItem
            url={breadcrumbItems[BreadcrumbLimitEnum.FirstItem].url}
            name={breadcrumbItems[BreadcrumbLimitEnum.FirstItem].name}
            index={BreadcrumbLimitEnum.FirstItem}
            isLast={false}
          />
          <ThreeDotIcon className="mt-4 cursor-pointer w-4 h-4" onClick={handleCollapse} />
          {showItems && <ShowCollapseBreadcrumbItems breadcrumbItems={breadcrumbItems} />}
          <span className="text-grayscale-300 mx-4"><GreaterThan/></span>
          <BreadcrumbItem
            url={breadcrumbItems[breadcrumbItems.length - 1].url}
            name={breadcrumbItems[breadcrumbItems.length - 1].name}
            index={breadcrumbItems.length - 1}
            isLast
          />
        </>
      );
    }
    return breadcrumbItems.map((item, index, array) => (
      <BreadcrumbItem {...item} index={index + 1} isLast={array.length - 1 === index} key={index} />
    ));
  };

  return (
    <ol className="flex items-center">
      <li className="flex items-center">
        <Link to="/">
          <BrandIcon />
        </Link>
        <span className="text-grayscale-300 mx-4"><GreaterThan/></span>
      </li>
      {renderBreadcrumbs()}
    </ol>
  );
};
