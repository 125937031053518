import { Header } from 'common/components/Header';
import { useOktaAuth } from '@okta/okta-react';
import { useRef, useState } from 'react';
import { WrappedSideBarMenu } from './WrappedSideBarMenu';
import classNames from 'classnames';
export interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const { authState } = useOktaAuth();

  const [isHamburguerMenuToggled, setIsHamburguerMenuToggled] = useState<boolean>(false);
  const isUserAuthenticated = authState?.isAuthenticated;
  const ref = useRef<HTMLDivElement>(null);

  if (!isUserAuthenticated) return <>{children}</>;

  return (
    <div className="h-screen grid grid-flow-column grid-rows-[auto_1fr] grid-cols-[auto_1fr]">
      <div className="col-span-2 px-6 l:col-span-1">
        <Header isHamburgerMenuToggled={isHamburguerMenuToggled} setIsHamburgerMenuToggled={setIsHamburguerMenuToggled} />
      </div>
      <div
        ref={ref}
        className={classNames(['col-span-2 l:block l:row-start-1 l:row-span-2 l:col-span-1', !isHamburguerMenuToggled && 'hidden'])}
      >
        <WrappedSideBarMenu bodyRef={ref} />
      </div>
      <div className={classNames(['col-span-2 l:block l:col-span-1 pl-6 overflow-auto', isHamburguerMenuToggled && 'hidden'])}>
        <div className="max-w-full">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
