import { ResourceAction } from 'api/authorization/userPermission';

export default class Permission {
  constructor(public service: string, public resource: string, public action: string) {}

  matches(action: ResourceAction) {
    return (
      (this.service === '*' || this.service === action.service) &&
      (this.resource === '*' || this.resource === action.resource) &&
      (this.action === '*' || this.action === action.action)
    );
  }
}
