export enum ScopeType {
  Brand = 'Brand',
  Group = 'Group',
  Store = 'Store',
  Unknown = 'Unknown',
}

export const BrandId = '1';

export default class Scope {
  static all = '*';

  static unknown = new Scope(ScopeType.Unknown, '');

  static brand = new Scope(ScopeType.Brand, BrandId);

  static group = (groupId: string) => new Scope(ScopeType.Group, BrandId, groupId);

  static store = (groupId: string, storeId: string) => new Scope(ScopeType.Store, BrandId, groupId, storeId);

  constructor(public type: ScopeType, public brandId: string, public groupId?: string, public storeId?: string) {}

  contains = (other: Scope) => {
    
    if (
      (other.type === ScopeType.Unknown) ||
      (this.type === ScopeType.Store && (other.type === ScopeType.Brand || other.type === ScopeType.Group)) ||
      (this.type === ScopeType.Group && other.type === ScopeType.Brand)
    )
      return false;

    switch (this.type) {
      case ScopeType.Brand:
        return this.brandId === other.brandId || this.brandId === Scope.all;
      case ScopeType.Group:
        return (
          (this.brandId === other.brandId || this.brandId === Scope.all) && (this.groupId === other.groupId || this.groupId === Scope.all)
        );
      case ScopeType.Store:
        return (
          (this.brandId === other.brandId || this.brandId === Scope.all) &&
          (this.groupId === other.groupId || this.groupId === Scope.all) &&
          (this.storeId === other.storeId || this.storeId === Scope.all)
        );
      default:
        throw Error(`Unexpected scope type: ${this.type}`);
    }
  };
}
