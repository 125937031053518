import equal from 'fast-deep-equal/es6';

export interface Filter<TValue> {
  $eq?: TValue | null;
  $lt?: TValue;
  $lte?: TValue;
  $gt?: TValue;
  $gte?: TValue;
  $in?: TValue[] | null;
  $like?: TValue;
}

const filterJsonFormatter = (key: string, value: any): any => {
  if (value === null) return undefined;

  if (key.startsWith('$') && Array.isArray(value)) {
    return value.map(val => (typeof val === 'object' ? val.id : val));
  }
  if (key.startsWith('$') && typeof value === 'object') {
    return value.id;
  }
  return value;
};

export const filterToString = (filter: Filter<any>): string | undefined => {
  if (
    filter.$gt == null &&
    filter.$gte == null &&
    filter.$lt == null &&
    filter.$lte == null &&
    filter.$like == null &&
    (filter.$in == null || !filter.$in.length)
  ) {
    if (filter.$eq != null && filter.$eq != '') {
      if (typeof filter.$eq === 'object') {
        return filter.$eq.id.toString();
      } else {
        return filter.$eq.toString();
      }
    } else {
      return undefined;
    }
  }
  if(filter.$like == '') return undefined;
  return JSON.stringify(filter, filterJsonFormatter);
};

export const filterEqual = <TValue>(thisFilter: Filter<TValue> | null | undefined, otherFilter: Filter<TValue> | null | undefined) => {
  // eslint-disable-next-line eqeqeq
  if (thisFilter == null || otherFilter == null) return thisFilter == otherFilter; // use '==' to equate undefined and null

  const { $in: filterIn, ...filter } = thisFilter!;
  const { $in: otherIn, ...other } = otherFilter!;
  return equal(filter, other) && filterInEqual({ $in: filterIn }, { $in: otherIn });
};

const filterInEqual = <TValue>(filter: Filter<TValue> | null | undefined, other: Filter<TValue> | null | undefined) => {
  let thisIn = null,
    otherIn = null;

  if (filter?.$in?.length) thisIn = filter.$in;
  if (other?.$in?.length) otherIn = other.$in;

  return equal(thisIn, otherIn);
};

export const getActiveFilterCount = <TFilters extends Record<keyof TFilters, Filter<any> | undefined>>(
  filters: TFilters,
  defaults: Readonly<TFilters>
) => {
  let count = 0;

  let filter: keyof TFilters;
  for (filter in filters) {
    if (!filterEqual(filters[filter], defaults[filter])) {
      if (!filterInEqual(filters[filter], defaults[filter])) {
        count += filters[filter]?.$in?.length ?? 0;
      } else {
        count++;
      }
    }
  }

  return count;
};

export const filtersEqual = <TFilters extends Record<keyof TFilters, Filter<any> | undefined>>(
  filters: Readonly<TFilters>,
  defaults: Readonly<TFilters>
) => {
  let filter: keyof TFilters;
  for (filter in filters) {
    if (!filterEqual(filters[filter], defaults[filter])) {
      return false;
    }
  }

  return true;
};