import { Avatar } from 'common/components/Avatar';
import { useUser } from 'common/hooks/useUser';
import classNames from 'classnames';

export interface HeaderProps {
  isCollapsed: boolean;
}

export const Header = ({ isCollapsed }: HeaderProps) => {
  const user = useUser();
  const name = user?.name ?? user?.preferred_username ?? user?.email;

  return (
    <div className={classNames('py-7 px-5 border-b border-grayscale-200', { 'flex flex-row': !isCollapsed })}>
      <Avatar isCollapsed={isCollapsed} name={name} />
      <div className={`${isCollapsed ? 'opacity-0' : 'opacity-1'} pl-5 pt-3 ease-in-out duration-200`}>
        <p className="text-base font-medium text-brand">{name}</p>       
      </div>
    </div>
  );
};
