import { MsalProvider, useAccount, useMsal } from '@azure/msal-react';
import { AccountInfo, AuthenticationResult, InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import React, { useCallback, useEffect, useState } from 'react';
import { useUser } from 'common/hooks/useUser';
import config from 'config/config';

const msal = new PublicClientApplication(config.azure.msal);

const AzureAuthentication = ({ children }: React.PropsWithChildren<{}>) => <MsalProvider instance={msal}>{children}</MsalProvider>;

export default AzureAuthentication;

export const useAccessToken = (scopes: string[]) => {
  const { email: loginHint } = useUser() ?? {};
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [apiData, setApiData] = useState<AuthenticationResult | null>(null);

  const getAccessToken = useCallback(async () => {
    if (!account && loginHint) {
      try {
        const response = await msal.ssoSilent({ loginHint, scopes });
        setApiData(response);
      } catch (e) {
        if (e instanceof InteractionRequiredAuthError) await msal.loginRedirect({loginHint, scopes});
      }
    } else if (account) {
      try {
        const response = await instance.acquireTokenSilent({ scopes, account });
        setApiData(response);
      } catch (e) {
        if (e instanceof InteractionRequiredAuthError) await instance.acquireTokenRedirect({scopes, account: instance.getActiveAccount() as AccountInfo});
      }
    }
  },[scopes, account, instance, loginHint]);

  useEffect(() => {
    getAccessToken();
  }, [scopes, account, instance, loginHint,getAccessToken]);

  return apiData?.accessToken;
};
