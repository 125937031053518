export class UserFeature {
  features: string[];

  constructor(features: string[]) {
    this.features = features;
  }

  hasMenuAccess = (feature: string): boolean => this.features.includes("Super User") || this.features.includes(feature);

}
