export interface HamburgerMenuProps {
  isHamburgerMenuToggled: boolean;
  setIsHamburgerMenuToggled: (isHamburgerMenuToggled: boolean) => void;
  HamburgerMenuStyle?: string;
}

export const HamburgerMenu = ({ isHamburgerMenuToggled, setIsHamburgerMenuToggled, HamburgerMenuStyle }: HamburgerMenuProps) => {
  const genericHamburgerLine = `h-1 w-6 my-1 rounded-full bg-grayscale-black transition ease transform duration-300`;

  return (
    <button
      className={`flex-col justify-center items-start xs:flex ${HamburgerMenuStyle ?? 'l:hidden'}`}
      onClick={() => setIsHamburgerMenuToggled(!isHamburgerMenuToggled)}
    >
      <div className={`${genericHamburgerLine} ${isHamburgerMenuToggled ? 'rotate-45 translate-y-4 opacity-100' : 'opacity-100 w-6'}`} />
      <div className={`${genericHamburgerLine} ${isHamburgerMenuToggled ? 'opacity-0' : 'opacity-100 w-7'}`} />
      <div className={`${genericHamburgerLine} ${isHamburgerMenuToggled ? '-rotate-45 opacity-100' : 'opacity-100 w-6'}`} />
    </button>
  );
};
