import { ToastContainer } from 'react-toastify';
import { CloseButton } from 'common/components/Toast/CloseToast';

export const ToastProvier = () => {
  return (
    <ToastContainer
      icon={false}
      hideProgressBar
      closeButton={CloseButton}
      toastClassName="flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer bg-orange-400"
      bodyClassName="text-sm font-white block p-3"
      position="top-right"
      autoClose={3000}
      closeOnClick={false}
      pauseOnHover
    />
  );
};
