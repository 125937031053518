import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as GreaterThan } from 'assets/icons/greater_than.svg';

interface BreadcrumbItemProps {
  url: string;
  name: string;
  index: number;
  isLast: boolean;
}

export const BreadcrumbItem = ({ url, name, index, isLast }: BreadcrumbItemProps) => {
  return (
    <React.Fragment>
      <li key={index} className="text-grayscale-400 text-xs ">
        <Link className='no-underline' key={index} to={url}>
          {name}
        </Link>
      </li>

      {isLast ? '' : <span className="text-grayscale-300 mx-4"><GreaterThan/></span>}
    </React.Fragment>
  );
};
