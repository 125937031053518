import { oktaAuth } from 'common/authentication/okta/OktaAuthentication';
import { Ketting, Resource, State } from 'ketting';
import { parse } from 'uri-template';
import { errorSubmitWrapper } from 'utils/errorSubmitWrapper';
import { PagedCollectionResource } from './resources';

export interface QueryResults<T extends object> {
  data: T[];
  resource: Resource<PagedCollectionResource>;
  state: State<PagedCollectionResource>;
}

export const getOptionalSubPath = (path: string, value?: string | null) =>
  value != null ? parse('{/subPath*}').expand({ subPath: [path, value] }) : '';

export const ClientAPI = (() => {
  let instance: Ketting;

  const createInstance = () => {
    const ketting = new Ketting('');
    delete ketting.contentTypeMap['text/html'];

    // Add Okta Access Token
    ketting.use((request, next) => {
      const bearerAuthHeader = 'Bearer ' + oktaAuth.getAccessToken();
      request.headers.set('Authorization', bearerAuthHeader);

      return next(request);
    });

    // Add test authentication headers
    ketting.use((request, next) => {
      const actorId: string = window.localStorage.getItem('Back-Office-Actor-Id')!;
      const actorType: string = window.localStorage.getItem('Back-Office-Actor-Type')!;

      request.headers.append('Back-Office-Actor-Id', actorId);
      request.headers.append('Back-Office-Actor-Type', actorType);

      return next(request);
    });

    return ketting;
  };

  const get = async <TValue extends object>(url: string, itemToFollow: string = 'item'): Promise<QueryResults<TValue>> => {
    try {
      const client = ClientAPI.getInstance();
      const resource = client.go<PagedCollectionResource>(url);
      const itemResources = await (await resource.refresh()).followAll<TValue>(itemToFollow);
      const data = await Promise.all(itemResources.map(async itemResource => (await itemResource.get()).data));
      const state = await resource.get();
      return { data, resource, state };
    } catch (error) {
      return redirectOnError(error);
    }
  };

  const patch = async (url: string, data: any) => {
    const client = ClientAPI.getInstance();
    const resource = client.go(url);
    await resource.patch({ data, headers: { 'Content-Type': 'application/json-patch+json' } });
  };

  const put = async (url: string, data: any) => {
    const client = ClientAPI.getInstance();
    const resource = client.go(url);
    await resource.put({ data });
    return { resource };
  };

  const post = async (url: string, data: any) => {
    const client = ClientAPI.getInstance();
    const resource = client.go(url);
    return await resource.post({ data });
  };

  const deleteById = async (url: string) => {
    try {
      const client = ClientAPI.getInstance();
      const resource = client.go(url);
      return await resource.delete();
    } catch (error) {
      return redirectOnError(error);
    }
  };

  const getById = async <TValue extends object = object>(url: string) => {
    try {
      const client = ClientAPI.getInstance();
      const resource = client.go<TValue>(url);
      resource.clearCache();
      const state = await resource.get();
      const result = (await resource.get()).data;
      return { data: result, resource, state };
    } catch (error: any) {
      return redirectOnError(error);
    }
  };

  const resolveUriTemplate = (url: string, variables: Record<string, unknown>) => parse(url).expand(variables);

  const redirectOnError = (error: any, redirectToErrorSubmitWrapper?: boolean) => {
    let status: { [key: number]: string } = {
      404: `/error/${error}`,
      500: `/error/${error}`,
      403: `/error/${error}`,
      401: `/error/${error}`,
    };
    if (error?.response?.status === 422) {
      redirectToErrorSubmitWrapper && errorSubmitWrapper({ error });
    } else window.location.href = status[error?.response?.status];
    return { data: undefined!, resource: undefined!, state: undefined!, error: error };
  };

  return {
    getInstance: () => {
      if (!instance) {
        instance = createInstance();
      }

      return instance;
    },
    get,
    put,
    post,
    patch,
    getById,
    deleteById,
    resolveUriTemplate,
    redirectOnError,
  };
})();
