import { useEffect, useState, RefObject, useCallback } from 'react';
import { ReactComponent as Home } from 'assets/icons/home.svg';
import { ReactComponent as Purchasing } from 'assets/icons/ic_purchasing.svg';
import { ReactComponent as Inventory } from 'assets/icons/inventory.svg';
import { ReactComponent as Organization } from 'assets/icons/organization.svg';
import { ReactComponent as Reporting } from 'assets/icons/ic_reporting.svg';
import { ReactComponent as Admin } from 'assets/icons/ic_admin.svg';
import { useHistory } from 'react-router-dom';
import { IMenuItem, SideBarNavigation } from 'common/components/SideBarNavigation';
import { Route } from 'Router';
import { OrganizationLabel, organizationRoutes } from 'organization/organization.routes';
import { PurchasingLabel, purchasingRoutes } from 'purchasing/purchasing.routes';
import { mapListPages } from 'Router/mapListPages';
import { InventoryLabel, inventoryRoutes } from 'inventory/inventory.routes';
import { RestaurantLabel, restaurantRoutes } from 'menu/menu.routes';
import { CashAndSalesLabel, cashAndSalesRoutes } from 'cashandsales/cashandsales.routes';
import { SubMenuItemProps } from 'common/components/SideBarNavigation/MenuItem/SubMenu/SubMenuItem';
import { usePermissions } from 'common/hooks/usePermissions';
import { AdminLabel, adminRoutes } from 'admin/admin.routes';
import { ReactComponent as CashDepositIcon } from 'assets/icons/cash_deposit.svg';
import { ReactComponent as RecipeIcon } from 'assets/icons/ic_recipes.svg';

export interface WrappedSideBarMenuProps {
  bodyRef: RefObject<HTMLDivElement>;
}

export const WrappedSideBarMenu = ({ bodyRef }: WrappedSideBarMenuProps) => {
  const history = useHistory();
  const permission = usePermissions();
  const [menuItemSelected, setMenuItemSelected] = useState<string>('Dashboard');
  const [selectedSubMenuItem, setSelectedSubMenuItem] = useState<string>('');
  const [hasClickOutsideMenu, setHasClickOutsideMenu] = useState<boolean>(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (bodyRef.current && !bodyRef.current?.contains(event.target as Node)) {
        setHasClickOutsideMenu(true);
        return;
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [bodyRef]);

  const IsMenuItemSelected = (key: string) => {
    return menuItemSelected.includes(key);
  };

  const handleMenuItemOnClick = (key: string) => {
    setHasClickOutsideMenu(false);
    if (IsMenuItemSelected(key)) {
      setMenuItemSelected('');
      return;
    }
    setMenuItemSelected(key);
  };

  const handleMenuClick = (label: string, path: string) => {
    history.push(path);
    setSelectedSubMenuItem('');
    handleMenuItemOnClick(label);
  };

  const handleClick = useCallback(
    (screen: Route) => {
      const { url, label } = screen;
      history.push(url);
      setSelectedSubMenuItem(label);
      setHasClickOutsideMenu(true);
    },
    [history]
  );

  const subMenusList = (subMenus: SubMenuItemProps[]): boolean =>
    subMenus && subMenus.filter((submenu: SubMenuItemProps) => submenu.hasSubMenuAccess).length > 0;

  const allRoutes = [
    {
      label: OrganizationLabel,
      icon: <Organization />,
      routes: organizationRoutes,
    },
    {
      label: RestaurantLabel,
      icon: <RecipeIcon className="w-5 h-5 fill-primary-700" />,
      routes: restaurantRoutes,
    },
    {
      label: InventoryLabel,
      icon: <Inventory />,
      routes: inventoryRoutes,
    },
    {
      label: PurchasingLabel,
      icon: <Purchasing />,
      routes: purchasingRoutes,
    },
    {
      label: CashAndSalesLabel,
      icon: <CashDepositIcon className='icon-reset active' />,
      routes: cashAndSalesRoutes,
    },
    {
      label: AdminLabel,
      icon: <Admin className='icon-reset active' />,
      routes: adminRoutes,
    }
  ].map(item => ({
    label: item.label,
    icon: item.icon,
    isMenuItemSelected: IsMenuItemSelected(item.label),
    onClick: () => handleMenuItemOnClick(item.label),
    subMenuItems: mapListPages(item.routes, handleClick, selectedSubMenuItem, permission),
    hasMenuAccess: subMenusList(mapListPages(item.routes, handleClick, selectedSubMenuItem, permission)),
  }));

  const menuItems: IMenuItem[] = [
    {
      label: 'Dashboard',
      icon: <Home />,
      isMenuItemSelected: IsMenuItemSelected('Dashboard'),
      onClick: () => handleMenuClick('Dashboard', '/'),
      hasMenuAccess: true,
    },
    ...allRoutes,
    {
      label: 'Reporting',
      icon: <Reporting className='icon-reset' />,
      isMenuItemSelected: IsMenuItemSelected('Reporting'),
      onClick: () => handleMenuItemOnClick('Reporting'),
      hasMenuAccess: false
    }
  ];
  return <SideBarNavigation menuItems={menuItems.filter(menu => menu.hasMenuAccess)} hideSubMenu={hasClickOutsideMenu} />;
};
