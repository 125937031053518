import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { ReactComponent as Logout } from 'assets/icons/ic_logout.svg';
import { Breadcrumb } from 'common/components/Breadcrumb';
import { HamburgerMenu } from 'common/components/HamburgerMenu';
import { BreadcrumbContext } from 'common/components/BreadcrumbContext';
import { useContext, useState } from 'react';
import { Button } from '../Button';
import { useOktaAuth } from '@okta/okta-react';
import { Modal } from '../Modal';

export interface HeaderProps {
  isHamburgerMenuToggled: boolean;
  setIsHamburgerMenuToggled: (isHamburgerMenuToggled: boolean) => void;
}

export const Header = ({ isHamburgerMenuToggled, setIsHamburgerMenuToggled }: HeaderProps) => {
  const breadcrumbLabel = useContext(BreadcrumbContext);
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const { oktaAuth } = useOktaAuth();

  return (
    <>
    <div className="flex flex-row h-[5.5rem] items-center">
      <div className="flex flex-1">
        <HamburgerMenu isHamburgerMenuToggled={isHamburgerMenuToggled} setIsHamburgerMenuToggled={setIsHamburgerMenuToggled} />
        {breadcrumbLabel.pageBreadcrumb.length > 0 ? (
          <div className="flex xs:flex-1 xs:items-center xs:justify-center l:justify-start">
            <Breadcrumb breadcrumbItems={breadcrumbLabel.pageBreadcrumb} />
          </div>
        ) : (
          <div className="flex xs:flex-1 xs:items-center xs:justify-center l:justify-start">
            <Logo />
          </div>
        )}
      </div>
      <div className="flex flex-row gap-10">
        <p className="pt-3 text-base font-medium text-primary-700 xs:hidden l:flex">Corporate Backoffice</p>
        <Button id="btn_logout" variant="None" className="pt-2 mr-4" onClick={() => setShowPrompt(true)}>
          <Logout className="w-8 h-8" />
        </Button>
      </div>
    </div>
    <Modal
            show={showPrompt}
            title={<p className='pl-3 font-bold'>Confirm Logout</p>}
            footerMessage='You will be redirected to login page.'
            handleClose={() => {
              setShowPrompt(false);
            }}
            button1Label="Cancel"
            onClickButton1={() => {
              setShowPrompt(false);
            }}
            button2Label={'Logout'}
            button2Type="submit"
            onClickButton2={ async () =>{ 
              await oktaAuth.signOut()
            }}
          >
            <p className='pl-3 text-black'>Are you sure you want to logout?</p>
          </Modal>
    </>
  );
};
