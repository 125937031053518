import { OktaAuthOptions } from '@okta/okta-auth-js';
import { Configuration } from '@azure/msal-browser';

declare global {
  let __DelTaco__BackOffice__Runtime__Configuration__: {
    okta: {
      issuer: string;
      clientId: string;
    };
    azure: {
      clientId: string;
      authority: string;
    };
    powerBi: {
      groupId: string;
    };
  };
}

export interface BackOfficeConfiguration {
  okta: {
    auth: OktaAuthOptions;
    signInWidget: {
      issuer: string;
      brandName: string;
      clientId: string;
      logo: string;
      logoText: string;
      redirectUri: string;
      postLogoutRedirectUri: string;
    };
  };
  azure: {
    msal: Configuration;
    powerBi: {
      groupId: string;
      scopes: string[];
    };
  };
  disableAuthorization: boolean;
}

if (window.localStorage.getItem('Back-Office-Actor-Id') == null) window.localStorage.setItem('Back-Office-Actor-Id', '-1');
if (window.localStorage.getItem('Back-Office-Actor-Type') == null) window.localStorage.setItem('Back-Office-Actor-Type', 'PERSON');

// @ts-ignore
let { okta, azure, powerBi, disableAuthorization } = window['__DelTaco__BackOffice__Runtime__Configuration__'];

const config: BackOfficeConfiguration = {
  okta: {
    auth: {
      issuer: okta.issuer,
      clientId: okta.clientId,
      redirectUri: window.location.origin + '/login/callback',
      pkce: true,
    },
    signInWidget: {
      issuer: okta.issuer,
      clientId: okta.clientId,
      brandName: 'Del Taco',
      logo: '/del-taco-logo.svg',
      logoText: 'Del Taco',
      redirectUri: window.location.origin + '/login/callback',
      postLogoutRedirectUri: window.location.origin + '/login/callback'
    },
  },
  azure: {
    msal: {
      auth: {
        clientId: azure.clientId,
        authority: azure.authority,
        redirectUri: '/',
        postLogoutRedirectUri: '/',
      },
    },
    powerBi: {
      groupId: powerBi.groupId,
      scopes: ['https://analysis.windows.net/powerbi/api/Report.Read.All'],
    },
  },
  disableAuthorization,
};

export default config;
