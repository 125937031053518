import { createContext, Dispatch, SetStateAction } from 'react';

export interface Breadcrumb {
  name: string;
  url: string;
}
interface PageBreadcrumb {
    pageBreadcrumb: Breadcrumb[],
    setBreadcrumbItems: Dispatch<SetStateAction<Breadcrumb[]>>
  }
  
export const BreadcrumbContext = createContext<PageBreadcrumb>({} as PageBreadcrumb)