import { FieldPath, UseFormReturn } from 'react-hook-form';
import { ValidationError } from 'api/ValidationError';
import { Toast } from 'common/components/Toast';
import { Problem } from 'ketting';

interface errorSubmitWrapperProps<T extends object> {
  error: unknown;
  formMethods?: UseFormReturn<T, any>;
}

export const errorSubmitWrapper = <T extends object>({ formMethods, error }: errorSubmitWrapperProps<T>) => {
  const handleSetError = (propertyPath: FieldPath<T>, { errorType, errorMessage }: { errorType: string; errorMessage: string }) => {
    if (formMethods) {
      const { setError } = formMethods;
      setError(propertyPath, { type: errorType, message: errorMessage });
    }
  };
  if (error instanceof Problem) {
    error.body.errors?.forEach(({ errorType, errorMessage, propertyPath }: ValidationError<T>) => {
      if (propertyPath && formMethods) handleSetError(propertyPath, { errorType, errorMessage });
      else Toast({ message: errorMessage, type: 'error' });
    });
  } else {
    Toast({
      message: `An unknown error has occurred.  Please relay the following information to Back Office Dev Team: ${error}`,
      type: 'error',
    });
  }
};
