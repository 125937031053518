
import { useState } from "react";
import { overrideTailwindClasses } from 'tailwind-override';
import { ariaType } from "./ButtonFunctions";
import {
  ButtonVariantEnum,
  IconButtonInnerSizeEnum,
  IconButtonSizeEnum,
  IconPositionEnum,
} from "./ButtonTypes";

export interface ButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>{
  children?: React.ReactNode;
  onClick?: () => void;
  variant?: 'Primary' | 'Secondary' | 'None';
  icon?: React.ReactNode;
  iconSize?: 'xxs' | 'xs' | 's' | 'm' | 'l';
  iconPosition?: 'Left' | 'Right';
  classNameSpan?: string;
  id: string;
  ariaFunction?: 'Normal' | 'Toggle' | 'Expanded';
  isAriaPressedOrExpanded?:boolean;
  idAriaElementExpanded?: string;
  padding?: string;
}

export const Button = ({
  children,
  variant,
  icon,
  iconPosition,
  className,
  classNameSpan,
  id,
  "aria-label": ariaLabel = id,
  iconSize,
  ariaFunction = "Normal",
  isAriaPressedOrExpanded = false,
  idAriaElementExpanded,
  disabled = false,
  type = 'button',
  onClick,
  ...buttonProps
}: ButtonProps) => {

  const [isAriaBoolean, setIsAriaBoolean] = useState(isAriaPressedOrExpanded)

  return (
    <button
      {...buttonProps}
      className={
        overrideTailwindClasses(`
          ${iconSize
            ? (
              ' border rounded-full border-primary-400 hover:bg-primary-50 hover:border-primary-500 focus:border-primary-300 focus:border-2 disabled:bg-transparent disabled:border-grayscale-300 disabled:cursor-not-allowed '
              + IconButtonSizeEnum[iconSize]
            )
            : (
              ButtonVariantEnum[variant ?? 'Primary']
            )
          }
          ${children ? '' : ' flex items-center justify-center ' }
          ${className ?? ''}
        `)
      }
      id={id}
      aria-label={ariaLabel}
      disabled={disabled}
      type={type}
      {...ariaType(ariaFunction, isAriaBoolean, idAriaElementExpanded)}
      onClick= {() => {
          if(!disabled){
            ariaFunction !== 'Normal' && setIsAriaBoolean(!isAriaBoolean);
            onClick && onClick();
          }
      }}
    >
      <span
        data-testid="span"
        className={
          overrideTailwindClasses(`
            ${iconSize
              ? (
                ' flex items-center justify-center border-none rounded-full border-2 '
                + IconButtonInnerSizeEnum[iconSize]
                + (disabled ? ' stroke-grayscale-300 ' : '  stroke-primary-500 ')
              )
              : `inline-flex items-center px-3 font-normal `
            }
            ${children ? '' : ' px-0 ' }
            ${classNameSpan ?? ''}
          `)
        }
      >
        {icon && iconPosition !== IconPositionEnum.Right && icon}
        {children}
        {icon && iconPosition === IconPositionEnum.Right && icon}
      </span>
    </button>
  );
};
