import React from 'react';
import { toast } from 'react-toastify';
import { CloseButton } from 'common/components/Toast/CloseToast';
import { ToastwithLink } from 'common/components/Toast/ToastwithLink';

export enum ToastTypeEnum {
  ERROR = 'error',
  INFO = 'info',
}
enum ToastTypePropertyEnum {
  error = '#D44333',
  info = '#333333',
  GrayscaleWhite = '#FFFFFF',
}

export interface ToastProps {
  message: string;
  type?: 'error' | 'info';
  link?: string;
}

export const Toast = ({ message, type, link }: ToastProps) => {
  if (type === ToastTypeEnum.INFO && link) {
    return (
      <>
        {toast(ToastwithLink({ message: message, linktext: link }), {
          style: { color: ToastTypePropertyEnum.GrayscaleWhite, background: ToastTypePropertyEnum[type ?? ToastTypeEnum.INFO] },
          type: type ?? ToastTypeEnum.INFO,
          closeButton: CloseButton,
        })}
      </>
    );
  }
  return (
    <>
      {toast(message, {
        style: { color: ToastTypePropertyEnum.GrayscaleWhite, background: ToastTypePropertyEnum[type ?? ToastTypeEnum.INFO] },
        type: type ?? ToastTypeEnum.INFO,
        closeButton: CloseButton,
      })}
    </>
  );
};
