import { Filter } from 'api/queries/filter';
import { Query } from 'api/queries/query';


export interface AuthorizationFilters {
  id: Filter<string>;
}

export const defaultQuery: Readonly<Query<AuthorizationFilters>> = new Query<AuthorizationFilters>({
  id: { $in: null },
});
