import { PermissionToken } from "api/authorization/userPermission";
import { UserFeature } from "api/authorization/userPermission/userFeatures";
import { createContext } from "react";

export interface AuthorizationContextProps {
    permissonToken: PermissionToken | null;
    feature: UserFeature | null;
}

export const AuthorizationContext = createContext<AuthorizationContextProps | null>(null! as AuthorizationContextProps);
