import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import OktaAuthentication, { SecureRoute } from 'common/authentication/okta/OktaAuthentication';
import Layout from 'layouts/Layout';
import { KettingProvider } from 'react-ketting';
import { ToastProvier } from 'common/components/Toast/ToastProvier';
import { mapAllPages } from 'Router/mapAllPages';
import { organizationRoutes } from 'organization/organization.routes';
import { inventoryRoutes } from 'inventory/inventory.routes';
import { restaurantRoutes } from 'menu/menu.routes';
import { purchasingRoutes } from 'purchasing/purchasing.routes';

import { Spinner } from 'common/components/Spinner';
import AzureAuthentication from 'common/authentication/azure/AzureAuthentication';
import { ClientAPI } from 'api/clientAPI';
import 'config/dayjs.config';
import './app.styles.scss';
import { BreadcrumbProvider } from 'common/components/BreadcrumbProvider';
import { ErrorBoundary } from 'react-error-boundary';
import { errorRoutes } from 'layouts/Layout/ErrorBoundary/errors.routes';
import ErrorPage from 'layouts/Layout/ErrorBoundary/error';
import { AuthorizationProvider } from 'common/authorization/AuthorizationContext/AuthorizationProvider';
import { adminRoutes } from 'admin/admin.routes';
import { cashAndSalesRoutes } from 'cashandsales/cashandsales.routes';

// Screen imports
const Login = lazy(() => import('Login'));
const Home = lazy(() => import('Home'));

const App = () => {
  return (
    <KettingProvider client={ClientAPI.getInstance()}>
      <OktaAuthentication>
        <ErrorBoundary fallbackRender={() => <ErrorPage />}>
          <AzureAuthentication>
            <AuthorizationProvider>
              <BreadcrumbProvider>
                <Layout>
                  <Switch>
                    <Suspense fallback={<Spinner />}>
                      <Route exact path="/login" component={Login} />
                      <SecureRoute exact path="/" component={Home} />
                      {mapAllPages([
                        organizationRoutes,
                        inventoryRoutes,
                        restaurantRoutes,
                        purchasingRoutes,
                        cashAndSalesRoutes,
                        adminRoutes,
                        errorRoutes,
                      ])}
                    </Suspense>
                  </Switch>
                </Layout>
              </BreadcrumbProvider>
              <ToastProvier />
            </AuthorizationProvider>
          </AzureAuthentication>
        </ErrorBoundary>
      </OktaAuthentication>
    </KettingProvider>
  );
};

export default App;
