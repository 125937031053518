import { SubMenuItem, SubMenuItemProps } from './SubMenuItem';

export interface SubMenuProps {
  isSelected: boolean;
  isCollapsed: boolean;
  menuTitle: string;
  subMenuItems: SubMenuItemProps[];
  hideSubMenu: boolean;
}

export const SubMenu = ({ isCollapsed, isSelected, menuTitle, subMenuItems, hideSubMenu }: SubMenuProps) => {
  const renderSubMenuItems = () => {
    return subMenuItems.map((item: SubMenuItemProps) => {
      return <SubMenuItem key={item.label} {...item} />;
    });
  };

  if (isSelected && !isCollapsed) {
    return <ul className="gap-[1px] flex flex-col">{renderSubMenuItems()}</ul>;
  }

  if (isSelected && isCollapsed && !hideSubMenu) {
    return (
      <ul className="gap-[1px] top-0 absolute left-13 flex flex-col bg-grayscale-white">
        <li className="w-[16.25rem] text-grayscale-white h-12 pl-7 bg-primary-500 flex items-center justify-start font-semibold">
          {menuTitle}
        </li>
        {renderSubMenuItems()}
      </ul>
    );
  }

  return <></>;
};
