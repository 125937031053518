import { Filter, filterToString, getActiveFilterCount, filtersEqual } from './filter';

export type IdName = { id: string; name: string };

export type Pagination = CursorPagination | PageOffsetPagination;

export interface CursorPagination {
  readonly page?: 'previous' | 'next';
  readonly cursor: any;
  readonly pageSize: number;
}

export interface PageOffsetPagination {
  readonly pageIndex?: number;
  readonly pageSize: number;
}

export const DefaultQueryPageSize = 20;
export const MaxQueryPageSize = 100;

export class Query<TFilters extends Record<keyof TFilters, Filter<any>>> {
  constructor(
    public readonly filters: TFilters,
    public readonly pagination: Pagination = { pageSize: DefaultQueryPageSize },
    public readonly searchTerm?: string
  ) {}

  filter = (filters: Partial<TFilters>) => new Query<TFilters>({ ...this.filters, ...filters }, this.pagination, this.searchTerm);

  pageSize = (pageSize: number) => new Query(this.filters, { pageSize }, this.searchTerm);
  maxPageSize = () => new Query(this.filters, { pageSize: MaxQueryPageSize }, this.searchTerm);
  /**
   * @deprecated use searchTerm filter instead for consistency reasons.
   */
  search = (searchTerm: string) => new Query(this.filters, this.pagination, searchTerm);

  serializeFilters = () => {
    return Object.entries(this.filters).reduce((obj: Record<keyof TFilters, string | undefined>, [key, filter]): Record<
      keyof TFilters,
      string | undefined
    > => {
      obj[key as keyof TFilters] = filterToString(filter as Filter<any>);
      return obj;
    }, {} as Record<keyof TFilters, string | undefined>);
  };

  getActiveFilterCount = (defaultQuery: Readonly<Query<TFilters>>) => getActiveFilterCount(this.filters, defaultQuery.filters);

  equals = (other: Readonly<Query<TFilters>>) => filtersEqual(this.filters, other.filters);
}
