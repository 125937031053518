import { useState } from "react";
import { BreadcrumbContext, Breadcrumb } from "../BreadcrumbContext";

interface ProviderProps {
    children: React.ReactNode;
}

export const BreadcrumbProvider = ({ children }: ProviderProps) => {
    const [pageBC, setPageBC] = useState<Breadcrumb[]>([])

    return <BreadcrumbContext.Provider value={{ pageBreadcrumb: pageBC, setBreadcrumbItems: setPageBC }}>
        {children}
    </BreadcrumbContext.Provider>
}