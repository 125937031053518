import { ServiceRouter } from 'Router';
import roleCreate from 'admin/role/create/roleCreate.route';
import roleEdit from './role/edit/roleEdit.route';
import roleDetails from './role/details/roleDetails.route';
import jobFunctionDetails from './jobFunction/details/jobFunctionDetails.route';
//import inventoryConfigurationCreateRoute from './inventoryConfiguration/Create/inventoryConfigurationList.route';
import referenceDataManagementList from './referenceDataManagement/List/referenceDataManagementList.route';
import ManageDMAReferenceData from './referenceDataManagement/manageReferenceData/organization/DMA/ManageDMAReferenceData.route';
import ManageJobCodeReferenceData from './referenceDataManagement/manageReferenceData/organization/JobFunction/ManageJobCodeReferenceData.route';
import ManageGroupsReferenceData from './referenceDataManagement/manageReferenceData/menu/Recipes/RecipeGroup/ManageGroupsReferenceData.route';
import ManageCategoryReferenceData from './referenceDataManagement/manageReferenceData/menu/Recipes/RecipeCategory/ManageCategoryReferenceData.route';
import ManageIngredientGroupReferenceDataRoute from './referenceDataManagement/manageReferenceData/menu/Ingredient/IngredientGroup/ManageIngredientGroupReferenceData.route';
import ManageIngredientCategoryReferenceDataRoute from './referenceDataManagement/manageReferenceData/menu/Ingredient/IngredientCategory/ManageIngredientCategoryReferenceData.route';
import ManagePromotionCategoryReferenceDataRoute from './referenceDataManagement/manageReferenceData/menu/Promotion/PromotionCategory/ManagePromotionCategoryReferenceData.route';
import ManageRecipeAttributeReferenceDataRoute from './referenceDataManagement/manageReferenceData/menu/Recipes/RecipeAttribute/ManageRecipeAttributeReferenceData.route';
import permissionsConfigurationListRoute from './permissionsConfiguration/List/permissionsConfigurationList.route';

export const AdminLabel = 'Admin';

export const adminRoutes: ServiceRouter = {
  ReferenceDataManagement: {
    list: referenceDataManagementList,
    manageDMA: ManageDMAReferenceData,
    manageJobCode: ManageJobCodeReferenceData,
    manageGroup: ManageGroupsReferenceData,
    manageCategory: ManageCategoryReferenceData,
    manageIngredientGroup: ManageIngredientGroupReferenceDataRoute,
    manageIngredientCategory: ManageIngredientCategoryReferenceDataRoute,
    managePromotionCategory: ManagePromotionCategoryReferenceDataRoute,
    manageRecipeAttribute: ManageRecipeAttributeReferenceDataRoute,
  },
  Role: {
    create: roleCreate,
    edit: roleEdit,
    details: roleDetails,
  },
  JobFunctions: {
    details: jobFunctionDetails,
  },
  Feature: {},
  PermissionsConfiguration: {
    list: permissionsConfigurationListRoute,
  },
  InventoryConfiguration: {
    // list: inventoryConfigurationCreateRoute,
  },
};
