import { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { UserClaims } from '@okta/okta-auth-js';

export const useUser = () => {
    const { authState, oktaAuth } = useOktaAuth();
    const [user, setUser] = useState<UserClaims | null>(null);
  
    useEffect(() => {
      if (authState?.isAuthenticated) {
        oktaAuth.getUser().then(setUser);
      }
    }, [authState, oktaAuth]);
  
    return user;
  }